import * as React from 'react';
import { Sys } from 'contentful';
import { useContentfulInspectorMode } from '@contentful/live-preview/react';
import { Wrapper } from 'src/containers';
import { Column, Stack } from 'src/components-v2/Layout';
import SxProps from 'threads5/themes/sx-props';
import Theme from 'threads5/themes/theme';
import { BackgroundContext } from 'src/contexts';
import ThemeProvider from 'threads5/themes/theme-provider';
import { useCmsMode } from 'src/contexts/ModeContext/ModeContext';
import { useComponentTheme } from 'src/hooks/useComponentTheme';
import { useCmsTheme } from 'src/contexts/CmsThemeContext/CmsThemeContextProvider';
import { ctaBannerStackStyles } from './CtaBanner.styles';
import { CmsThemes } from 'src/lib/constants';

interface IBanner {
  children: React.ReactNode;
  sx?: SxProps<Theme>;
  innerSx?: SxProps<Theme>;
  sys?: Sys;
  bgImage?: string;
  className?: string;
  mode?: string;
  backgroundColor?: string;
  intrinsicMode?: string;
}

const CtaBanner: React.FunctionComponent<IBanner> = ({
  children,
  sx,
  innerSx,
  sys,
  bgImage,
  className,
  mode,
  backgroundColor = 'white',
  intrinsicMode,
}) => {
  const inspectorProps = useContentfulInspectorMode({
    entryId: sys?.id,
  });
  const bgSource =
    bgImage ||
    '//images.ctfassets.net/ss5kfr270og3/7HOKN7T3pSrW62Cpxias0t/a3aa4c23dc5610132d1fe5c2bfc9a40c/CTA_Banner_Art.png';

  const cmsTheme = useCmsTheme();
  const isV2 = cmsTheme === CmsThemes.WEB2;

  // for web2 support, text color was based on if there was a bg image or not - assumed always to be a light background image
  // we're adding support to follow the mode field, but in web2 this still needs a distinctive light/dark color to not regress any existing content
  // in web 3 we should be using the mode + background selection to make the correct color mode choices
  const cmsMode = useCmsMode({
    mode: mode,
    backgroundColor:
      (isV2 ? (bgImage ? 'white' : 'black') : backgroundColor) || 'white',
  });
  const componentTheme = useComponentTheme(intrinsicMode || cmsMode);
  const background = componentTheme.palette[backgroundColor] || backgroundColor;

  return (
    <ThemeProvider theme={componentTheme}>
      <Wrapper
        component='section'
        sx={{
          background,
          ...sx,
        }}
        {...inspectorProps({ fieldId: 'internalTitle' })}
        className={className}
      >
        <BackgroundContext.Provider // updated value for always light bg cta banner
          value={
            isV2
              ? bgImage
                ? 'white'
                : 'black'
              : cmsMode === 'light'
              ? 'white'
              : 'black'
          }
        >
          <Column lg={20} lgOffset={2} sm={24} sx={{ width: '100%' }}>
            <Stack
              direction={{ xs: 'column', xl: 'row' }}
              alignItems={{ xs: 'center', md: 'start', xl: 'center' }}
              textAlign={{ xs: 'center', md: 'left' }}
              justifyContent='space-between'
              gap={2.5}
              sx={{
                ...ctaBannerStackStyles?.[cmsTheme],
                borderRadius: '24px',
                backgroundColor: 'black',
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                backgroundImage: `url("https:${bgSource}?fm=webp&w=2740&q=60")`,
                '& h2, & p': {
                  mb: 0,
                },
                ...innerSx,
              }}
            >
              {children}
            </Stack>
          </Column>
        </BackgroundContext.Provider>
      </Wrapper>
    </ThemeProvider>
  );
};

export default CtaBanner;
