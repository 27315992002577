import * as React from 'react';
import { RichTextSwitch } from 'src/containers/RichTextSwitch';
import { Btn as Button } from 'src/components-v2/Inputs';
import { CmsThemes, targetMap } from 'src/lib/constants';
import { useContentfulInspectorMode } from '@contentful/live-preview/react';

import { Box, Column, Stack } from 'src/components-v2/Layout';
import { Wrapper } from 'src/containers';
import { CmsMediaSwitch } from 'src/components-v2/Media';
import { BackgroundContext } from 'src/contexts';
import ThemeProvider from 'threads5/themes/theme-provider';
import { useComponentTheme } from 'src/hooks/useComponentTheme';
import { useCmsMode } from 'src/contexts/ModeContext/ModeContext';
import { useCmsTheme } from 'src/contexts/CmsThemeContext/CmsThemeContextProvider';
import {
  ctaAssetBannerContainerStyles,
  ctaAssetBannerStyles,
  ctaAssetContentWrapperStyles,
} from './CmsCtaAssetBanner.styles';

import cx from 'classnames';
import styles from './CmsCtaAssetBanner.module.scss';

export const CmsCtaAssetBanner = ({ fields, sys }) => {
  const bgColor = fields?.backgroundColor?.toLowerCase() || 'white';
  const cmsTheme = useCmsTheme();
  const cmsMode = useCmsMode({
    mode: fields?.intrinsicMode || fields?.mode,
    backgroundColor: bgColor,
  });
  const componentTheme = useComponentTheme(cmsMode);
  const background = componentTheme.palette[bgColor] || bgColor;

  // figure out the mode / backgrounds for the card
  // web 2 always uses light background
  // web 3 has a dark / light mapping
  const cardMode = cmsTheme === CmsThemes.WEB3 ? cmsMode : 'light';
  const cardTheme = useComponentTheme(cardMode);

  const inspectorProps = useContentfulInspectorMode({
    entryId: sys?.id,
  });

  return (
    <ThemeProvider theme={componentTheme}>
      <Wrapper
        sx={{
          background,
          ...fields?.styles,
        }}
        component='section'
        {...inspectorProps({ fieldId: 'internalTitle' })}
      >
        <Column
          lg={20}
          lgOffset={2}
          sm={24}
          sx={{
            ...ctaAssetBannerContainerStyles[cmsTheme],
          }}
        >
          <Stack
            sx={{
              position: 'relative',
              ...ctaAssetBannerStyles[cmsTheme],
            }}
            direction={{
              xs: 'column',
              sm: fields?.isFlipped ? 'row-reverse' : 'row',
            }}
            className={cx(styles.CmsCtaAssetBanner)}
          >
            <Box
              component='div'
              className={cx(styles.imageWrapper)}
              sx={{
                background,
                '& .lazyload-wrapper': {
                  height: '100%',
                },
              }}
            >
              {fields?.asset && (
                <CmsMediaSwitch
                  asset={{
                    ...fields?.asset?.fields,
                    isLazy: true,
                    styles: {
                      height: '100%',
                      width: '100%',
                      objectFit: 'cover',
                      ...fields?.asset?.fields?.styles,
                    },
                  }}
                  queryString={{
                    xs: `?fm=webp&w=1222&q=60`,
                    sm: `?fm=webp&w=932&q=60`,
                    md: `?fm=webp&w=1116&q=60`,
                    lg: `?fm=webp&w=1356&q=60`,
                  }}
                  width={678} // setting a default width in case it was not provided in CMS
                  height={529} // setting a default height in case it was not provided in CMS
                  sys={fields?.asset?.sys}
                />
              )}
            </Box>
            <Box
              className={cx(styles.contentWrapper)}
              sx={
                ctaAssetContentWrapperStyles({
                  isFlipped: fields?.isFlipped,
                })[cmsTheme]
              }
            >
              <ThemeProvider theme={cardTheme}>
                <BackgroundContext.Provider
                  value={cardMode === 'light' ? 'white' : 'black'}
                >
                  <RichTextSwitch
                    sys={sys}
                    content={fields?.body}
                    nodes={{
                      h2: {
                        variant: 'h4',
                        component: 'h3',
                        fieldId: 'body',
                      },
                      h6: {
                        variant: 'h6',
                        component: 'h2',
                        fieldId: 'body',
                      },
                      p: {
                        variant: 'p2',
                        component: 'p',
                        fieldId: 'body',
                      },
                    }}
                  />
                  <Button
                    variant={
                      fields?.cta?.fields?.appearance.toLowerCase() ||
                      'tertiary'
                    }
                    href={fields?.cta?.fields?.url || ''}
                    target={targetMap[fields?.cta?.fields?.target] || '_self'}
                    trackingId={fields?.cta?.fields?.trackingId || ''}
                    sys={fields?.cta?.sys}
                    isInverted={true}
                    sx={{ ...fields?.cta?.fields?.styles }}
                  >
                    {fields?.cta?.fields?.text}
                  </Button>
                </BackgroundContext.Provider>
              </ThemeProvider>
            </Box>
          </Stack>
        </Column>
      </Wrapper>
    </ThemeProvider>
  );
};
