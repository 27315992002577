import { useState, useEffect, createContext, useContext, useRef } from 'react';
import { useReducedMotion } from '@react-spring/web';
import { useTrack } from 'src/contexts/AnalyticsContext';
import { PERFORMANCE_EVENTS } from 'src/lib/trackingEvents';
import useDeviceSize from './useDeviceSize';

const PerformanceContext = createContext<boolean>(false);

export const PerformanceProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const track = useTrack();
  const [isLowPerformance, setIsLowPerformance] = useState(false);
  const prefersReducedMotion = useReducedMotion();
  const hasRun = useRef(false);
  const device = useDeviceSize();

  useEffect(() => {
    // Lower iterations to reduce workload and memory usage.
    const iterations = 1e6;
    const trials = 5;
    let totalBenchmarkTime = 0;

    // Define a benchmark function that does some CPU-intensive work.
    const benchmark = () => {
      let total = 0;
      for (let i = 0; i < iterations; i++) {
        total += Math.sqrt(i);
      }
      return total;
    };

    // Warm-up to trigger JIT optimizations.
    benchmark();

    // Run the benchmark several times and accumulate the total time.
    for (let i = 0; i < trials; i++) {
      const start = performance.now();
      benchmark();
      totalBenchmarkTime += performance.now() - start;
    }

    const avgTime = totalBenchmarkTime / trials;
    const isMobile = device.width <= 1024;
    const isLowCpuPerformance = isMobile ? avgTime > 30 : avgTime > 11.25;
    setIsLowPerformance(prefersReducedMotion || isLowCpuPerformance);

    if (!hasRun.current) {
      hasRun.current = true;
      track({
        type: isLowCpuPerformance
          ? PERFORMANCE_EVENTS.IS_LOW_PERFORMANCE
          : PERFORMANCE_EVENTS.IS_NOT_LOW_PERFORMANCE,
        payload: {
          avgTime,
          isMobile,
        },
      });
    }
  }, [device.width, prefersReducedMotion, track]);

  return (
    <PerformanceContext.Provider value={isLowPerformance}>
      {children}
    </PerformanceContext.Provider>
  );
};

const useIsPotato = () => {
  try {
    const isLowPerformance = useContext(PerformanceContext);
    return isLowPerformance;
  } catch (error) {
    return true;
  }
};

export default useIsPotato;
